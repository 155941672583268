import { useContext } from 'react'
import SettingsContext from 'context/settings.context'

const UseSettings = (): any => {
  const context = useContext(SettingsContext)

  return context
}

export default UseSettings
