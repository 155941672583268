// core
import { NextPage } from 'next'

// 3rd party
import Grid from '@material-ui/core/Grid'

// local
import { App } from 'containers'

const AppWithAuth: NextPage = () => (
  <Grid container style={{ minHeight: '100vh' }}>
    <App />
  </Grid>
)
export default AppWithAuth
